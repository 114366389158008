import { ssoHost } from 'omni-app-container';
import { fetchJSON } from 'omni-ui';

const originsByEnv = {
  local: 'http://0.0.0.0:5002',
  dev: 'https://devframework-template.api.annalect.com',
  qa: 'https://qaframework-template.api.annalect.com',
  stg: 'https://stgframework-template.api.annalect.com',
  prod: 'https://framework-template.api.annalect.com',
};

// Lightweight memoize
function memoize(method) {
  const cache = {};
  return async (...args) => {
    const key = JSON.stringify(args);
    cache[key] = cache[key] || method.apply(this, args);
    return cache[key];
  };
}

class FrameworkTemplateAPI {
  constructor() {
    this.initialized = new Promise(resolve => {
      this.setInitialized = resolve;
    });
  }

  init(env, token) {
    let ready = false;
    this.initialized.then(() => {
      ready = true;
    });
    if (!ready) this.setInitialized(true);
    this.env = env;
    this.token = token;
  }

  // Make fetch errors readable and wrap into Error object
  static FetchFailedError = class FetchFailedError extends Error {
    constructor(json) {
      // console.log(json);
      let msg = `Fetch failed: ${JSON.stringify(json)}`;
      if ('errors' in json) {
        let errors = [json.errors];
        if (json.errors instanceof Object) {
          errors = Object.entries(json.errors).map(([k, v]) => {
            const val = v instanceof Array ? v.join(', ') : JSON.stringify(v);
            return `  • ${k}: ${val}`;
          });
        }
        msg = `The operation failed due to the following errors:\n${errors.join(
          '\n'
        )}`;
      }
      super(msg);
      this.json = json;
    }
  };

  // Reusable functionality used by all get/create/update/delete calls to API
  goFetch = async (
    path,
    {
      method = 'GET',
      body = null,
      keyFilter = null,
      apiUrl,
      download = false,
    } = {}
  ) => {
    // Wait until initialized until using this.env or this.token
    await this.initialized;

    const opts = {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
    if (body !== null) {
      if (keyFilter !== null) {
        const copy = {};
        Object.entries(body).forEach(([k, v]) => {
          if (keyFilter.includes(k)) copy[k] = v;
        });
        body = copy; // eslint-disable-line no-param-reassign
      }

      if (Object.values(body).some(value => value instanceof File)) {
        // Data contains a file upload: content type changes and body is FormData
        delete opts.headers['Content-Type']; // Browser will set it for us
        opts.body = new FormData();
        Object.entries(body).forEach(([key, value]) =>
          opts.body.append(key, value)
        );
      } else {
        // Normal json body
        opts.body = JSON.stringify(body);
      }
    }

    const response = await fetch(
      `${apiUrl ?? originsByEnv[this.env]}/${path}`,
      opts
    );
    if (response.status !== 200) {
      let error;
      try {
        error = await response.json();
        error.status = response.status;
      } catch (e) {
        error = { status: response.status };
      }
      throw new FrameworkTemplateAPI.FetchFailedError(error);
    }

    if (download !== false) {
      let filename;
      try {
        filename = response.headers
          .get('Content-Disposition')
          .split('filename=')[1]
          .split(';')[0]
          .replace(/['"]/g, '');
      } catch (e) {
        filename = download;
      }
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a); // append element to the dom (required in firefox)
      a.click();
      a.remove(); // afterwards remove the element
      return '';
    }

    const json = await response.json();
    return json;
  };

  /* ********** HISTORY ********** */
  listHistoryItems = async ({ page, page_size, search }) => {
    const params = new URLSearchParams();
    params.append('page', page);
    params.append('page_size', page_size);
    if (search?.personGuidList) {
      search.personGuidList.forEach(param => {
        params.append('person_guid', param);
      });
    }
    if (search?.object_type) {
      search.object_type.forEach(param => {
        params.append('object_type', param);
      });
    }
    if (search?.uuid_object) params.append('uuid_object', search.uuid_object);
    if (search?.object_name) params.append('object_name', search.object_name);
    if (search?.startDate) params.append('start_date', search.startDate);
    if (search?.endDate) params.append('end_date', search.endDate);
    if (search?.action_type) {
      search.action_type.forEach(param => {
        params.append('action_type', param);
      });
    }
    return this.goFetch(`history/?${params.toString()}`);
  };

  listHistoryUsers = async () => {
    return this.goFetch(`/history/users`);
  };

  /* ********** FRAMEWORK TEMPLATES ********** */
  listFrameworkTemplates = async ({ fields, moduleId } = {}) => {
    const params = new URLSearchParams();
    if (fields) params.append('fields', fields.join(','));
    if (moduleId) params.append('uuid_module', moduleId);
    return this.goFetch(`framework_template/?${params.toString()}`);
  };

  listClientFrameworkTemplates = async clientId => {
    return this.goFetch(`framework_template/client/${clientId}`);
  };

  getFrameworkTemplate = async id => {
    return this.goFetch(`framework_template/${id}/?check_count=t`);
  };

  getFrameworkTemplateModules = async id => {
    return this.goFetch(`framework_template/${id}/?schema=modules`);
  };

  updateFrameworkTemplate = async (id, data) => {
    const validKeys = [
      'name',
      'description',
      'css_overrides',
      'diagram',
      'active',
      'data_points',
      'assigned_clients',
      'overview_layout',
      'step_lock_timeout',
    ];
    return this.goFetch(`framework_template/${id}/?check_count=t`, {
      method: 'PUT',
      body: data,
      keyFilter: validKeys,
    });
  };

  createFrameworkTemplate = async data => {
    const validKeys = [
      'name',
      'active',
      'description',
      'css_overrides',
      'diagram',
      'data_points',
      'assigned_clients',
      'overview_layout',
      'step_lock_timeout',
    ];
    return this.goFetch('framework_template/', {
      method: 'POST',
      // diagram is still a required field in API; using empty string per MN-620
      body: { ...data, diagram: '' },
      keyFilter: validKeys,
    });
  };

  cloneFrameworkTemplate = async (id, data) => {
    const validKeys = [
      'name',
      'active',
      'assigned_clients',
      'step_lock_timeout',
    ];
    return this.goFetch(`framework_template/${id}/clone/`, {
      method: 'POST',
      body: data,
      keyFilter: validKeys,
    });
  };

  deleteFrameworkTemplate = async id => {
    return this.goFetch(`framework_template/${id}/`, { method: 'DELETE' });
  };

  /* ***************** STEPS ***************** */
  getStep = async id => {
    return this.goFetch(`step/${id}/?check_count=t`);
  };

  updateStep = async (id, data) => {
    const validKeys = [
      'name',
      'description',
      'display_order',
      'display_order_label',
      'css_coordinates',
      'data_points',
    ];
    return this.goFetch(`step/${id}/?check_count=t`, {
      method: 'PUT',
      body: data,
      keyFilter: validKeys,
    });
  };

  createStep = async data => {
    const validKeys = [
      'uuid_framework_template',
      'parent_id',
      'name',
      'description',
      'display_order',
      'display_order_label',
      'css_overrides',
      'data_points',
    ];
    return this.goFetch('step/', {
      method: 'POST',
      body: data,
      keyFilter: validKeys,
    });
  };

  cloneStep = async (id, data) => {
    const validKeys = [
      'name',
      'description',
      'display_order',
      'display_order_label',
      'parent_id',
    ];
    return this.goFetch(`step/${id}/clone/`, {
      method: 'POST',
      body: data,
      keyFilter: validKeys,
    });
  };

  deleteStep = async stepId => {
    return this.goFetch(`step/${stepId}/`, { method: 'DELETE' });
  };

  /* **************** ASSETS ***************** */
  createAsset = async (stepId, data) => {
    const validKeys = [
      'uuid_content_type',
      'name',
      'description',
      'type',
      'asset',
    ];
    return this.goFetch(`step/${stepId}/asset/`, {
      method: 'POST',
      // type is still a required field in API; using string 'link' per MN-621
      body: {
        ...data,
        type: 'link',
        // uuid_content_type is still a required field in API; uuid_content_type is static across env
        uuid_content_type: 'fb90f792-8b88-4ffb-a506-dbac7b9353b5',
      },
      keyFilter: validKeys,
    });
  };

  getAsset = async stepId => {
    return this.goFetch(`step/${stepId}/asset/`);
  };

  updateAsset = async (stepId, assetId, data) => {
    const validKeys = [
      'uuid_content_type',
      'name',
      'description',
      'type',
      'asset',
    ];
    return this.goFetch(`step/${stepId}/asset/${assetId}/`, {
      method: 'PUT',
      body: data,
      keyFilter: validKeys,
    });
  };

  deleteAsset = async (stepId, assetId) => {
    return this.goFetch(`step/${stepId}/asset/${assetId}`, {
      method: 'DELETE',
    });
  };

  listAssets = async stepId => {
    return this.goFetch(`step/${stepId}/assets/`);
  };

  /* ************* CONTENT TYPES ************* */
  listContentTypes = async () => {
    return this.goFetch('content_type/list/');
  };

  createContentType = async data => {
    const validKeys = ['name', 'description', 'icon'];
    return this.goFetch('content_type/', {
      method: 'POST',
      // icon is still a required field in API; using empty string per MN-619
      body: { ...data, icon: '' },
      keyFilter: validKeys,
    });
  };

  updateContentType = async (id, data) => {
    const validKeys = ['name', 'description', 'icon'];
    return this.goFetch(`content_type/${id}/`, {
      method: 'PUT',
      body: data,
      keyFilter: validKeys,
    });
  };

  /* *************** APP LINKS ***************** */
  addStepAppLink = async (stepId, data) => {
    return this.goFetch(`step/${stepId}/app/`, {
      method: 'POST',
      body: data,
    });
  };

  updateStepAppLink = async (stepId, appId, data) => {
    return this.goFetch(`step/${stepId}/app/${appId}`, {
      method: 'PUT',
      body: data,
    });
  };

  deleteStepAppLink = async (stepId, appId) => {
    return this.goFetch(`step/${stepId}/app/${appId}`, {
      method: 'DELETE',
    });
  };

  /* *************** STEP MODULES ***************** */
  listModules = async ({ fields } = {}) => {
    const params = new URLSearchParams();
    if (fields) params.append('fields', fields.join(','));
    return this.goFetch(`module/?${params.toString()}`);
  };

  getModule = async moduleId => {
    return this.goFetch(`module/${moduleId}`);
  };

  addStepModules = async (stepId, data) => {
    return this.goFetch(`step/${stepId}/module/`, {
      method: 'POST',
      body: { modules: data },
    });
  };

  updateStepModules = async (stepId, data) => {
    return this.goFetch(`step/${stepId}/module/`, {
      method: 'PUT',
      body: { modules: data },
    });
  };

  removeStepModule = async (stepId, uuidModule) => {
    const removedUuid = { uuid: uuidModule };
    return this.goFetch(`step/${stepId}/module/`, {
      method: 'DELETE',
      body: { modules: [removedUuid] },
    });
  };

  createModule = async data => {
    const validKeys = [
      'name',
      'description',
      'module_type',
      'data_structure',
      'uuid_module_template',
    ];
    return this.goFetch('module/', {
      method: 'POST',
      body: data,
      keyFilter: validKeys,
    });
  };

  updateModule = async (id, data) => {
    const validKeys = [
      'name',
      'description',
      'module_type',
      'data_structure',
      'assist_fields',
    ];
    return this.goFetch(`module/${id}/`, {
      method: 'PUT',
      body: data,
      keyFilter: validKeys,
    });
  };

  deleteModule = async uuidModule => {
    return this.goFetch(`module/${uuidModule}/`, {
      method: 'DELETE',
    });
  };

  /* ************* SETTINGS EXPORTS ************* */
  updateFrameworkTemplateSettings = async (uuid, clientId, data) => {
    const validKeys = ['pptx_name', 'pptx_file', 'theme_object'];
    return this.goFetch(`framework_template/${uuid}/settings/${clientId}/`, {
      method: 'PUT',
      body: data,
      keyFilter: validKeys,
    });
  };

  // DELETE one field based on keys provided
  deleteFrameworkTemplateSettings = async (uuid, clientId, keys) => {
    return this.goFetch(
      `framework_template/${uuid}/settings/${clientId}/?settings=${keys.join(
        ','
      )}`,
      {
        method: 'DELETE',
      }
    );
  };

  downloadFrameworkTemplatePowerpoint = (
    uuid,
    clientId,
    filename = 'template.pptx'
  ) => {
    this.goFetch(
      `framework_template/${uuid}/settings/${clientId}/?settings=pptx_file`,
      { download: filename }
    );
  };

  /* *************** CLIENTS ***************** */
  listClients = memoize(async () => {
    // Wait until initialized until using this.env or this.token
    await this.initialized;

    const path = `api/clients?mapped=true&ANsid=${window.AN.sso.session.sid}`;
    const portalAPI = 'https://' + ssoHost(this.env, 'omni.annalect.com');
    const response = await this.goFetch(path, { apiUrl: portalAPI });
    return response.data
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(c => ({ id: c.guid_client, name: c.name }));
  });

  /* *************** AGENTS ***************** */
  listGlobalAgents = () => {
    // Hard-coded list of globally-accessible agents, for now
    const names = [
      'Content Assist',
      'Standard',
      'Omni Learning and Development',
    ];
    return names
      .sort((a, b) => a.localeCompare(b))
      .map(name => ({ name, id: name }));
  };

  listAgents = memoize(async client => {
    const host = ssoHost(this.env, 'omniagentstore-api.annalect.com');
    const url = `https://${host}/v1/agents?clientId=${client}`;

    let agents = (
      await fetchJSON(url, {
        headers: { ansid: window.AN.sso.session.sid },
      })
    ).data.results;

    // Ignore image generation agents. For now if it has "image" in the name remove it
    agents = agents.filter(agent => !agent.name.match(/\bimage\b/i));

    agents.forEach(agent => {
      // For some reason the agent store uses names as identifiers
      agent.id = agent.name;
    });
    return agents.sort((a, b) => a.name.localeCompare(b.name));
  });

  /* *************** MODULE TYPES ***************** */
  // Get ALL module types
  listModuleTemplates = async () => this.goFetch('module_template/');

  // Get ONE module type
  getModuleTemplate = async uuidModuleTemplate =>
    this.goFetch(`module_template/${uuidModuleTemplate}`);

  // add module types
  addModuleTemplates = async data => {
    return this.goFetch(`module_template/`, {
      method: 'POST',
      body: data,
    });
  };

  // update module type
  updateModuleTemplates = async (uuidModuleTemplate, data) => {
    const validKeys = [
      'description',
      'image_url',
      'template',
      'name',
      'assist_fields',
    ];
    return this.goFetch(`module_template/${uuidModuleTemplate}/`, {
      method: 'PUT',
      body: data,
      keyFilter: validKeys,
    });
  };

  /* *************** CUSTOM STATUSES ***************** */
  addCustomStatus = async (ftId, data) => {
    return this.goFetch(`framework_template/${ftId}/status/`, {
      method: 'POST',
      body: data,
    });
  };

  batchAddCustomStatuses = async (ftId, data) => {
    return this.goFetch(`framework_template/${ftId}/status/batch/`, {
      method: 'POST',
      body: data,
    });
  };

  editCustomStatus = async (ftId, statusId, data) => {
    return this.goFetch(`framework_template/${ftId}/status/${statusId}/`, {
      method: 'PUT',
      body: data,
    });
  };

  deleteCustomStatus = async (ftId, statusId) => {
    return this.goFetch(`framework_template/${ftId}/status/${statusId}/`, {
      method: 'DELETE',
    });
  };

  /* *************** MODULE ASSIST FIELDS ***************** */
  getModuleAssistField = async (moduleId, field) => {
    return this.goFetch(`module/${moduleId}/assist_field/${field}`);
  };

  listModuleAssistFields = async moduleId => {
    return this.goFetch(`module/${moduleId}/assist_field/`);
  };

  addModuleAssistField = async (moduleId, fieldId, data) => {
    return this.goFetch(`module/${moduleId}/assist_field/${fieldId}/`, {
      method: 'POST',
      body: data,
    });
  };

  editModuleAssistField = async (moduleId, fieldId, data) => {
    return this.goFetch(`module/${moduleId}/assist_field/${fieldId}/`, {
      method: 'PUT',
      body: data,
      keyFilter: ['field_id', 'prompt'], // Remove 'agents'
    });
  };

  deleteModuleAssistField = async (moduleId, fieldId) => {
    return this.goFetch(`module/${moduleId}/assist_field/${fieldId}/`, {
      method: 'DELETE',
    });
  };

  /* *************** MODULE ASSIST FIELD AGENTS ***************** */
  listModuleAssistFieldAgents = async (moduleId, fieldId) => {
    return this.goFetch(`module/${moduleId}/assist_field/${fieldId}/agent`);
  };

  updateModuleAssistFieldAgent = async (moduleId, fieldId, clientId, data) => {
    const path = `module/${moduleId}/assist_field/${fieldId}/agent/${clientId}`;
    return this.goFetch(path, {
      method: 'PUT',
      body: data,
    });
  };

  deleteModuleAssistFieldAgent = async (moduleId, fieldId, clientId) => {
    const path = `module/${moduleId}/assist_field/${fieldId}/agent/${clientId}`;
    return this.goFetch(path, { method: 'DELETE' });
  };
}

export const api = new FrameworkTemplateAPI();
